<!-- ----------------------------------------------------------------------- -->
<!--                           Continuation Modal                            -->
<!-- ----------------------------------------------------------------------- -->
<ng-template #continuationModal let-modal>
  <!-- Header -->
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      {{ "Checkout.ContinuationModal.ContinueLaterQuestion" | translate }}
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Body -->
  <div class="modal-body cont-modal-body flex-column">
    <!--  <i class="ion-md-alert flex-centered" style="color: red;"></i> -->
    <hr />
    <p class="flex-centered">
      {{ "Checkout.ContinuationModal.ReferenceIdText" | translate }}
      <b>{{ orderedQuote?.quoteInfo.requestReferenceId }}</b>
    </p>
    <p class="flex-centered">
      {{ "Checkout.ContinuationModal.BuyBeforeText" | translate }}
      <b>
        {{
        orderedQuote.quoteInfo.quotationEndDate
        | date : "EEEE, MMMM d, y" : "" : lang
        }}</b>
    </p>
  </div>
  <!-- Footer -->
  <div class="modal-footer">
    <button class="primary-btn" (click)="saveQuote()">
      <span>{{ "App.AgreeText" | translate }}</span>
      <span *ngIf="isSavingOrder" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    </button>
    <button (click)="modal.dismiss()" class="primary-btn danger">
      <span>{{ "App.CancelText" | translate }}</span>
    </button>
  </div>
</ng-template>

<!-- ----------------------------------------------------------------------- -->
<!--                           connect lean  Modal                            -->
<!-- ----------------------------------------------------------------------- -->

<ng-template #leanModal let-modal>
  <!-- Header -->
  <div class="modal-header border-bottom-0">
    <!-- <h4 class="modal-title" id="modal-basic-title">
        {{ "Checkout.ContinuationModal.ContinueLaterQuestion" | translate }}
      </h4> -->
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Body -->
  <div class="modal-body cont-modal-body flex-column lean-modal mb-5">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <h2 class="connect-header mb-3">
            <b>{{ "LeanModal.connect_bank" | translate }}</b>
          </h2>
          <p class="text-muted">
            {{ "LeanModal.To_verify" | translate }}
          </p>
          <p class="connect-desc1 text-muted">
            {{ "LeanModal.In_return" | translate }}
          </p>
          <p class="m-0 text-muted">
            <b>{{ "LeanModal.consent_details" | translate }}</b>
          </p>
          <p class="connect-desc2 text-muted">
            {{ "LeanModal.consenting" | translate }}
            <b>{{ "LeanModal.lean_tech" | translate }}</b>
            {{ "LeanModal.and" | translate }}
            <b>{{ "LeanModal.company_name" | translate }}</b>
          </p>
          <div class="connect-date my-2">
            <b>{{ "LeanModal.Access" | translate }}
              {{ leanAccessTo | date : "d/MM/yyyy h:mm a" }}</b>
          </div>
          <h5 class="data-header mb-3 mt-5">
            {{ "LeanModal.what_data" | translate }}
          </h5>
          <hr />
          <div class="account-details d-flex justify-content-between align-items-baseline" (click)="collapse.toggle()"
            [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">
            <h6 class="m-0">
              <i class="icon ion-md-albums mx-2"></i>
              <b>{{ "LeanModal.account_details" | translate }}</b>
            </h6>
            <p><i class="icon ion-ios-arrow-down"></i></p>
          </div>
          <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
            <p class="text-muted">{{ "LeanModal.account_name" | translate }}</p>
          </div>
          <div class="d-flex justify-content-center mt-5">
            <button class="link-btn btn w-100" (click)="modal.dismiss(); getDetailsForLean()">
              {{ "LeanModal.Link_account" | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-4 px-0">
  <div class="row">
    <!-- ----------------------------------------------------------------------- -->
    <!--                         Policy Details                                  -->
    <!-- ----------------------------------------------------------------------- -->
    <div class="col-md-8">
      <div class="boxed-item mt-3">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-6">
              <table class="table-sm">
                <tr>
                  <th>
                    {{ "Checkout.PolicyDetails.PolicyStart" | translate }}:
                  </th>
                  <td> {{orderedQuote.quoteInfo.products[0].policyEffectiveDate | date : "dd-MM-yyyy"}}</td>
                </tr>
                <tr>
                  <th>
                    {{ "Checkout.PolicyDetails.ProductType" | translate }}:
                  </th>
                  <td>
                    {{
                    lang === "ar"
                    ? orderedQuote.quoteInfo.products[0].nameAr
                    : orderedQuote.quoteInfo.products[0].name
                    }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{ "Checkout.PolicyDetails.IdentityNumber" | translate }}:
                  </th>
                  <td>{{ orderedQuote.insuredInfo.identityNumber }}</td>
                </tr>
                <tr>
                  <th>{{ "Checkout.PolicyDetails.FullName" | translate }}:</th>
                  <td *ngIf="lang === 'en'">
                    {{ orderedQuote.insuredInfo.firstName }}
                    {{ orderedQuote.insuredInfo.middleName }}
                    {{ orderedQuote.insuredInfo.lastName }}
                  </td>
                  <td *ngIf="lang === 'ar'">
                    {{ orderedQuote.insuredInfo.firstNameAr }}
                    {{ orderedQuote.insuredInfo.middleNameAr }}
                    {{ orderedQuote.insuredInfo.lastNameAr }}
                  </td>
                </tr>
                <tr>
                  <th>{{ "Checkout.PolicyDetails.Birthdate" | translate }}:</th>
                  <td>{{ orderedQuote.vehicleDrivers[0].birthDate }}</td>
                </tr>
              </table>
            </div>
            <div class="col-lg-4">
              <table class="table-sm">
                <tr *ngIf="orderedQuote.vehicleInfo.vehicleIdTypeId == 1">
                  <th>
                    {{ "Checkout.PolicyDetails.SequenceNum" | translate }}:
                  </th>
                  <td>{{ orderedQuote.vehicleInfo.vehicleId }}</td>
                </tr>
                <tr *ngIf="orderedQuote.vehicleInfo.vehicleIdTypeId == 2">
                  <th>{{ "Landing.CustomCardText" | translate }}:</th>
                  <td>{{ orderedQuote.vehicleInfo.vehicleId }}</td>
                </tr>
                <tr>
                  <th>
                    {{
                    "Checkout.PolicyDetails.VehicleDescription" | translate
                    }}:
                  </th>
                  <td>
                    {{ orderedQuote.vehicleInfo.model }}
                    {{ orderedQuote.vehicleInfo.plateNumber }}
                  </td>
                </tr>
                <tr>
                  <th>
                    {{
                    "Checkout.PolicyDetails.VehicleMajorColor" | translate
                    }}:
                  </th>
                  <td>{{ orderedQuote.vehicleInfo.colorAr }}</td>
                </tr>

                <tr>
                  <th>
                    {{ "Checkout.PolicyDetails.RepairsLocation" | translate }}:
                  </th>
                  <td>
                    {{
                    lang === "ar"
                    ? orderedQuote.vehicleInfo.repairMethodAr
                    : orderedQuote.vehicleInfo.repairMethod
                    }}
                  </td>
                </tr>
              </table>
            </div>
            <div class="col-lg-2 d-flex align-items-center">
              <!-- Vehicle Illustration -->
              <img class="brand-image" *ngIf="orderedQuote?.vehicleInfo.makerLogo" [src]="vehicleLogoSrc" />
            </div>
          </div>
        </div>
      </div>
      <!-- ----------------------------------------------------------------------- -->
      <!--                           Vehicle  Driver                               -->
      <!-- ----------------------------------------------------------------------- -->
      <div class="boxed-item mt-3 mb-3">
        <h5 class="card-header">
          {{ "Checkout.Drivers.VehicleDrivers" | translate }}
        </h5>
        <div class="card-body">
          <table class="table table-borderless">
            <thead>
              <tr>
                <th>
                  {{
                  "QuotationRequestForms.DriverList.NationalIDLabel"
                  | translate
                  }}
                </th>
                <th>{{ "Misc.FullName" | translate }}</th>
                <th>
                  {{ "QuotationRequestForms.DriverInfo.Birth" | translate }}
                </th>
                <th>{{ "Checkout.Drivers.DrivingPercentage" | translate }}</th>
              </tr>
            </thead>
            <tbody class="bg-light">
              <tr *ngFor="let driver of orderedQuote.vehicleDrivers">
                <td>{{ driver.identityNumber }}</td>
                <td>
                  {{ lang === "ar" ? driver.fullNameAr : driver.fullName }}
                </td>
                <td>{{ driver.birthDate }}</td>
                <td>{{ driver?.drivingPercentageId }}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- ----------------------------------------------------------------------- -->
      <!--                           upload vehicle images                         -->
      <!-- ----------------------------------------------------------------------- -->
      <div class="boxed-item mt-3 mb-3" *ngIf="quoteProduct.productTypeId === 2">
        <h5 class="card-header">{{ "Checkout.VehicleImage" | translate }}</h5>
        <div class="card-body">
          <!-- Success Alert -->
          <div class="container">
            <div class="alert-box p-0 m-0" *ngIf="isSendImageLinkseccessfuly" id="errorAlert">
              <ngb-alert class="alert col-12" type="success" (close)="
                  isSendImageLinkseccessfuly = !isSendImageLinkseccessfuly
                ">
                <small style="font-size: 15px">
                  <i class="icon ion-md-alert pl-1"></i>
                  {{
                  lang == "ar"
                  ? "تم ارسال رسالة الي هاتفك, قم بزيارة الرابط الموجود في محتوي الرسالة لرفع صور المركبة"
                  : successMessage
                  }}
                </small>
              </ngb-alert>
            </div>
          </div>

          <!-- Error Alert -->
          <div class="container">
            <div class="row alert-box p-0 m-0" *ngIf="isSendImageLinkFailed" id="errorAlert">
              <ngb-alert class="alert col-12" type="danger" (close)="isSendImageLinkFailed = !isSendImageLinkFailed">
                <small style="font-size: 15px">
                  <i class="icon ion-md-alert pl-1"></i>
                  {{ errorMessage }}
                </small>
              </ngb-alert>
            </div>
          </div>

          <div class="alert alert-info flex-container" role="alert" *ngIf="!isImagesUploadedSuccessfully">
            <i class="ion-md-information-circle-outline flex-centered" style="margin: auto 10px; font-size: 24px"></i>
            <span class="flex-centered" style="margin: auto 0">{{ "Checkout.CompImagesUpload" | translate
              }}<a *ngIf="!isResndSMSVisible" (click)="sendUploadImageRequest()" href="javascript:void(0)" [ngClass]="{
                  visited: isSMSLinkVisited
                }">
                <span *ngIf="isSendSMSLoading"
                  class="iban-bank-loader spinner-border spinner-border-sm secondary-lime flex-centered" role="status"
                  aria-hidden="true" style="margin-left: 5px; margin-right: 5px"></span>
                <span>{{ "Checkout.UploadImageRequest" | translate }}</span></a>
              <span *ngIf="isResndSMSVisible" style="color: #00a2ae; padding: 5px; font-weight: bold">{{
                "Checkout.RESEND_SMS" | translate
                }}<span style="padding: 5px">{{
                  transform(counter)
                  }}</span></span>
            </span>
          </div>

          <!-- Upload message & Preview Images -->
          <div class="alert p-0 flex-container flex-column" role="alert" *ngIf="isImagesUploadedSuccessfully">
            <div class="container">
              <div class="alert-box p-0 m-0" id="errorAlert">
                <ngb-alert class="alert col-12 text-center" type="success">
                  <small style="font-size: 15px">
                    <i class="icon ion-md-checkmark-circle-outline pl-1"></i>
                    {{"SuccessMessages.ImageUploaded" | translate}}
                  </small>
                </ngb-alert>
              </div>
            </div>
            <!-- image preview -->
            <div class="row mx-2 justify-content-between">
              <div *ngFor="let img of imageList" class="col-md-2 mx-2 p-2 imgCard">
                <!-- <span class="d-block">{{this.imgTitle}}</span> -->
                <img style="height: 100px; border-radius: 5px;" [src]="'data:image/png;base64,' + img.imageMedia"
                  alt="Car Images">
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ----------------------------------------------------------------------- -->
      <!--                        Payment                                          -->
      <!-- ----------------------------------------------------------------------- -->

      <!--Errors Alert-->
      <div class="boxed-item my-3">
        <h5 class="card-header">
          {{ "Checkout.Payment.Payment" | translate }}
        </h5>
        <div class="card-body">
          <form [formGroup]="checkoutDetailsFormGroup">
            <div class="row align-items-center alert-box p-0 m-0" *ngIf="isErrorAlertVisible && hiddedErrorAlert"
              id="errorAlert">
              <ngb-alert class="alert col-12" type="danger" (close)="isErrorAlertVisible = !isErrorAlertVisible">
                <p class="m-0">
                  <i class="icon ion-md-alert"></i>
                  {{ errorMessage }}
                </p>
                <small *ngIf="validationErrors">
                  <ul class="errors-list">
                    <li *ngFor="let error of validationErrors">
                      {{ error }}
                    </li>
                  </ul>
                </small>
              </ngb-alert>
            </div>
            <!-- Disclaimer -->
            <div class="alert alert-info flex-container" role="alert">
              <i class="ion-md-information-circle-outline flex-centered" style="margin: auto 10px; font-size: 24px"></i>
              <span class="flex-centered" style="margin: auto 0">{{
                "Checkout.Payment.IbanNote" | translate
                }}</span>
            </div>
            <div class="row my-3">
              <div class="col-lg-2 col-md-2 col-md-12 iban-group">
                <span *ngIf="isBankLogoLoading"
                  class="iban-bank-loader spinner-border spinner-border-sm secondary-lime flex-centered" role="status"
                  aria-hidden="true"></span>
                <img class="iban-bank-logo flex-centered" *ngIf="
                    ibanBankLogoSrc &&
                    !isBankLogoLoading &&
                    checkoutForm.ibanCtrl.valid &&
                    isEnteredIbanValid
                  " (error)="ibanBankLogoSrc = null" [src]="ibanBankLogoSrc" alt="" style="width: 100px" />
              </div>
              <div class="col-lg-10 col-md-10 col-sm-12 mb-2 input-group iban-group" [class.col-lg-12]="
                  !ibanBankLogoSrc || checkoutForm.ibanCtrl.errors
                ">
                <div class="input-group-append">
                  <span class="input-group-text">IBAN</span>
                </div>
                <!-- IBAN Input -->
                <input formControlName="ibanCtrl" type="text" name="iban" placeholder="IBAN"
                  class="iban-ipt form-control" maxlength="29" [ngClass]="{
                    'is-invalid':
                      checkoutForm.ibanCtrl.errors &&
                      checkoutForm.ibanCtrl.touched
                  }" />
                <div class="input-group-append">
                  <button (click)="verifyIban()" class="verify-btn" [disabled]="checkoutForm.ibanCtrl.invalid"
                    [ngClass]="{
                      'verify-btn':
                        isEnteredIbanValid == undefined ||
                        isEnteredIbanValid === true,
                      'not-verified-btn': isEnteredIbanValid === false
                    }">
                    <span *ngIf="isVerifyIbanLoading"
                      class="iban-bank-loader spinner-border spinner-border-sm secondary-lime flex-centered"
                      role="status" aria-hidden="true" style="color: #fff; margin-left: 5px; margin-right: 5px"></span>
                    <span *ngIf="isEnteredIbanValid == undefined">Verify Iban</span>
                    <span *ngIf="isEnteredIbanValid === false">Not Verified</span>
                    <span *ngIf="isEnteredIbanValid === true">Verified</span>
                  </button>
                </div>
                <!-- Bank Logo (Based on entered IBAN) -->
              </div>
              <!-- IBAN Validation Errors -->
              <div *ngIf="checkoutForm.ibanCtrl.errors" class="iban-bank-logo">
                <div *ngIf="
                      checkoutForm.ibanCtrl.touched &&
                      checkoutForm.ibanCtrl.dirty &&
                      checkoutForm.ibanCtrl.errors.invalidIban
                    " class="v-meassage flex-centered vertically-padded">
                  {{ "Errors.InvalidIban" | translate }}
                </div>
                <div *ngIf="
                      checkoutForm.ibanCtrl.touched &&
                      checkoutForm.ibanCtrl.errors.required
                    " class="v-meassage flex-centered vertically-padded">
                  {{ "Errors.EmptyInputField" | translate }}
                </div>
              </div>
            </div>
          </form>
          <div class="col-12 my-3 p-0">
            <!-- choosing payment method toggles -->
            <!-- <th class="my-2">{{ "Checkout.PaymentMethodTxt" | translate }}</th> -->
            <div class="payment-type">
              <div class="types d-flex justify-content-around">
                <button class="btn-grad" [class.selected]="paymentMethod.isCardPayment"
                  (click)="openConfirmPaymentModal(content2)">
                  <!-- (click)="cardPayment()" -->
                  {{ "Checkout.Pay" | translate }}
                  <!-- <div class="row" style="
                      margin-left: 2px;
                      margin-right: 2px;
                      text-align: center;
                      align-items: center;
                    ">
                    <div class="col-4" style="padding-left: 0px; padding-right: 0px">
                      <img src="assets/images/payment-brands/Mada.png" style="width: 100%" alt="american-express" />
                    </div>
                    <div class="col-4" style="padding-left: 0px; padding-right: 0px">
                      <img src="assets/images/payment-brands/Visa.png" style="width: 100%" alt="visa card" />
                    </div>
                    <div class="col-4" style="padding-left: 0px; padding-right: 0px">
                      <img src="assets/images/payment-brands/MasterCard.png" style="width: 100%" alt="master card" />
                    </div>
                  </div> -->
                </button>
                <!-- <button class="type m-1" [class.selected]="paymentMethod.isSadadPayment" (click)="sadadPayment()"
                  style="text-align: center; align-items: center">
                  <img src="assets/images/payment-brands/Sadad.png" style="width: 70px" alt="apple pay" />
                </button> -->
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 mt-2 p-0">
              <!-- <div *ngIf="completeChekout" class="loader-card mb-3">
                <div class="content-container loader-wrapper flex-centered flex-container mt-5">
                  <div class="lds-facebook flex-centered">
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </div>
              </div> -->

              <!-- <div *ngIf="viewPaymentCard"> -->
              <app-directpay [dataPayment]='dataPayment'></app-directpay>
              <!-- </div> -->

              <div *ngIf="showErrorHiddenAlert">
                <div class="card text-center mt-2" *ngIf="isErrorAlertVisible" style="max-width: 100rem; height: 10rem">
                  <div class="card-body py-3">
                    <h5 class="card-title">
                      <i class="ion-md-warning flex-centered" style="
                          margin: auto 10px;
                          font-size: 34px;
                          color: #cab261;
                        "></i>
                    </h5>
                    <p class="card-text">{{ errorMessage }}</p>
                  </div>
                </div>
              </div>
              <!-- <div
                *ngIf="paymentMethod.isApplePayment && !isLoadingPaymentCard"
              >
                <div
                  class="card text-center mt-2"
                  style="max-width: 100rem; height: 10rem"
                >
                  <div class="card-body py-3">
                    <h5 class="card-title">
                      <i
                        class="ion-md-warning flex-centered"
                        style="
                          margin: auto 10px;
                          font-size: 34px;
                          color: #cab261;
                        "
                      ></i>
                    </h5>
                    <p class="card-text">{{ errorMessage }}</p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>

    <!-- ----------------------------------------------------------------------- -->
    <!--                        Insurance Company   Details                      -->
    <!-- ----------------------------------------------------------------------- -->

    <div class="col-lg-4" [class.is-sticky]="hasScrolledBanner">
      <div class="boxed-item mt-3 mb-3">
        <img *ngIf="orderedQuote?.quoteInfo.insuranceCompany.logo" [src]="getCompanyLogo()"
          class="company-image d-block mx-auto" />
        <h5 class="card-header d-flex justify-content-center">
          {{ "Checkout.Coupon" | translate }}
        </h5>
        <div class="col-sm-12 col-md-6 col-lg-12">
          <label>{{
            "Checkout.CouponType" | translate
            }}</label>
          <div class="form-group">
            <form [formGroup]="couponForm">
              <ng-select formControlName="CouponType" bindValue="name" [items]="Coupon" bindLabel="name">
              </ng-select>
              <label>
                {{
                ""
                | translate
                }}
              </label>
              <div class="input-group">
                <input type="text" class="form-control" formControlName="Coupon" />
                <button type="submit" class="btn btn-primary rounded-0" (click)="applyCoupon()">Apply</button>
              </div>
            </form>
          </div>
          <h5 class="card-header d-flex justify-content-center">
            {{ "Checkout.CostsDetails" | translate }}
          </h5>
          <ul class="list-group mb-3" class="checkout-card p-0">
            <li class="list-group-item">
              <!-- ----------------------------------------------------------------------- -->
              <!--                            Show Amount Details                           -->
              <!-- ----------------------------------------------------------------------- -->
              <!-- Extra Benefits -->
              <div class="prices-preview" *ngFor="let amount of amountDetails; let i = index">
                <tr [ngClass]="{ 'last-item': i === amountDetails.length - 1 }" style="font-weight: bold">
                  <td>
                    <div>
                      <span [ngClass]="amount.bold ? 'total' : 'vat'">
                        {{ lang == "ar" ? amount.nameAr : amount.name }}
                      </span>
                    </div>
                  </td>
                  <td [ngClass]="amount.bold ? 'total' : 'price'">
                    <span class="currency-style">
                      {{ amount.price | number : "1.2-2" }}
                    </span>
                    <small style="color: #007bef; font-weight: bold;" [ngClass]="amount.bold ? 'total' : 'currency'">{{
                      "App.Currency" | translate
                      }}</small>
                  </td>
                </tr>
                <hr *ngIf="amount.underLine" />
              </div>
              <!-- </table> -->
            </li>
          </ul>
          <!-- Available Discounts -->
          <div class="prices-preview mx-3 py-3" style="font-weight: bold">
            <tr *ngFor="
              let discount of orderedQuote.quoteInfo.products[0].deductibles[0]
                .discounts
            ">
              <td>
                <div>
                  <span>
                    {{ lang == "ar" ? discount.nameAr : discount.name }}
                  </span>
                </div>
              </td>
              <td style="color: #00a2ae" class="mb-2">
                <span class="currency">
                  {{ discount.discountAmount | number : "1.2-2" }}
                  <small class="currency">{{ "App.Currency" | translate }}</small>
                </span>
              </td>
            </tr>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ********************************** -->
  <!-- lean integration  -->
  <div id="lean-link"></div>

</div>

<!-- confirmation template -->
<ng-template #content2 let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h4 class="modal-title text-dark" id="modal-basic-title">
      {{ "Quote.ModalTitle" | translate }}
    </h4>
  </div>
  <div class="modal-body">
    <p>{{ "Quote.ModalBody" | translate }}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-info" (click)="c(orderPayment())">
      {{ "App.Accept" | translate }}
    </button>
    <button type="button" class="btn btn-danger" (click)="c('Cnacel click')">
      {{ "App.CancelText" | translate }}
    </button>
  </div>
</ng-template>

<!-- verify template -->
<ng-template #verify let-c="close" let-d="dismiss" id="verifyAccount">
  <div class="">
    <div class="container justify-content-center pb-4">
      <div class="d-flex flex-column justify-content-between">
        <div class="d-flex justify-content-end mt-3 mx-1">
          <i class="icons ion-md-close-circle cross" (click)="closeActiveModal()"></i>
        </div>
        <div class="card two bg-white p-4 text-center">
          <div class="p-2">
            <div class="flex-container flex-centered">
              <div class="flex-column flex-centered">
                <img src="../../../../../assets/images/wazenLogo.png" alt="WazenLogo" class="wazen-logo" />
                <label>{{ "Auth.VerifyPayText" | translate }} {{quoteProduct.deductibles[0].policyPremium}} {{
                  "App.Currency" | translate
                  }}</label>
              </div>
            </div>
          </div>
          <!-- ------------------------------ Alert(s) ------------------------------- -->
          <!-- Error(s) Alert -->
          <div *ngIf="
              isVerifyAccountErrorAlertVisible ||
              isVerifyAccountSuccessAlertVisible
            " class="alert-wrapper mt-1">
            <ngb-alert *ngIf="isVerifyAccountErrorAlertVisible" type="danger" [dismissible]="true" (onClosed)="
                isVerifyAccountErrorAlertVisible =
                  !isVerifyAccountErrorAlertVisible
              ">
              <small>{{ errorMessage }}</small>
              <small *ngIf="verifyAccountValidationErrors">
                <ul class="errors-list">
                  <li *ngFor="let error of verifyAccountValidationErrors">
                    {{ error }}
                  </li>
                </ul>
              </small>
            </ngb-alert>
            <!-- Success Alert -->
            <ngb-alert *ngIf="isVerifyAccountSuccessAlertVisible" type="success" [dismissible]="true" (onClosed)="
                isVerifyAccountSuccessAlertVisible =
                  !isVerifyAccountSuccessAlertVisible
              ">
              <small>{{ successMessage }}</small>
            </ngb-alert>
          </div>

          <!-- <div class="mb-3" *ngIf="counter == 0">
            <span class="verification-code">{{
              "Auth.ExpiredOTP" | translate
              }}</span>
          </div> -->

          <div class="flex-container flex-centered" style="width: 100%">
            <div class="flex-centered">
              <!-- Validation Code Input -->
              <div class="col-lg-12 row d-flex justify-content-center">
                <ng-otp-input (onInputChange)="onOtpChange($event)"
                  [ngStyle]="{'text-align' : 'center','width':'100%', 'direction':'ltr'}"
                  [config]="{length: 6 , allowNumbersOnly:true}"></ng-otp-input>
                <div *ngIf="
                  isSubmitting &&
                  (!verificationCode ||
                    verificationCode.toString().length === 0)
                ">
                  <div class="v-meassage mx-5">
                    <span>{{ "Errors.EmptyInputField" | translate }}</span>
                  </div>
                </div>
                <!-- Submit Button -->
                <button [disabled]="isRequested" [ngClass]="isRequested ? 'secondary-btn' : 'primary-btn'"
                  (click)="validateVerificationCode()"
                  class="verification-code-btn primary-btn form-control d-block my-2">
                  <span>{{ "App.ContinueText" | translate }}</span>
                  <span *ngIf="isVerifyAccountLoading && !isResending" class="spinner-border spinner-border-sm"
                    role="status" aria-hidden="true"></span>
                </button>

              </div>

              <!-- Resend email link -->
              <div class="col-lg-12">
                <!-- <span class="verification" *ngIf="!isExpired">{{'Auth.Duration' | translate}}</span> -->
                <span class="verification">
                  {{ "Auth.Duration" | translate }}
                  <span class="text-dark"> {{ transform(counter) }}</span>
                </span>

                <div class="d-flex justify-content-center align-items-center">
                  <div class="me-1 foot">{{ "Auth.RESEIVE_CODE" | translate }}</div>
                  <div class="btn-foot">
                    <button (click)="resendVerficationCode()" [disabled]="counter !== 0" [ngStyle]="
                    counter !== 0 ? { color: '#7f8087' } : { cursor: 'pointer' }
                          ">
                      {{ "Auth.SEND_AGAIN" | translate }}
                    </button>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>