import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  ViewChild,
  AfterViewInit,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import AppUtils from "src/app/helpers/utils/AppUtils";
import CheckoutUtils from "src/app/helpers/utils/CheckoutUtils";
import { checkPhoneNumberValidation } from "src/app/helpers/validators/checkPhoneNumberValidation";
import { IbanValidator } from "src/app/helpers/validators/IbanValidator";
import { AppRoutes } from "src/app/models/app/AppRoutes";
import { LocallyStoredItemsKeys } from "src/app/models/app/LocallyStoredItemsKeys";
import BanksIds from "src/app/models/checkout/BanksIds";
import { InsuranceOfferPreview } from "src/app/models/insurance-steps/InsuranceOfferPreview";
import { PolicyPurchase } from "src/app/models/order-preview/PolicyPurchase";
import { Benefit } from "src/app/models/quote/Benefit";
import { PremiumBreakDown } from "src/app/models/quote/PremiumBreakDown";
import { Product } from "src/app/models/quote/Product";
import { ApiResponse } from "src/app/payload/responses/ApiResponse";
import { PreviewQuoteResponse } from "src/app/payload/responses/quotes/PreviewQuoteResponse";
import { AppService } from "src/app/services/app/app.service";
import { OrderReviewService } from "src/app/services/checkout/order-review.service";
import { QuoteService } from "src/app/services/quote/quote.service";
import { environment } from "src/environments/environment";
import {
  CheckoutReturnState,
  dataPayment,
} from "src/app/models/order-preview/CheckoutReturnState";
import { FileUploader } from "ng2-file-upload";
import { FileHandle } from "src/app/models/order-preview/FileHandle";
import { Subscription, of, timer } from "rxjs";
import { PaymentMethod } from "src/app/models/order-preview/PaymentMethod";
import { SendUploadImagesLinkRequest } from "src/app/models/order-preview/SendUploadImagesLinkRequest";
import { delay, mergeMap, retryWhen, take, takeWhile } from "rxjs/operators";
import { Iban } from "src/app/models/checkout/Iban";
import {
  IbanRequest,
  IbanVerificationRequest,
} from "src/app/models/order-preview/PreviewPageRequest";
import { ApplyCoupon } from "src/app/models/order-preview/ApplyCoupon";
import { coupon } from "src/app/models/order-preview/coupon";
import { RegistrationResponse } from "src/app/payload/responses/auth/RegistrationResponse";
import { AuthService } from "src/app/services/auth/auth.service";
import { UserVerificationOTP } from "src/app/payload/requests/auth/UserLogin";
import { DirectpayComponent } from "../directpay/directpay.component";
import { PurchaseResponse } from "./../../../models/order-preview/PurchaseResponse";

declare const hyperpayModule: any;
declare const $: any;
declare let Lean: any;
@Component({
  selector: "app-order-review",
  templateUrl: "./order-review.component.html",
  styleUrls: ["./order-review.component.css"],
})
export class OrderReviewComponent implements OnInit, AfterViewInit {
  /* External */
  @Input() quoteProduct?: Product;
  @ViewChild("paymentbox", { static: true }) paymentBox: ElementRef;
  @ViewChild("verify", { static: true }) verify: ElementRef;
  @ViewChild(DirectpayComponent) directPay: DirectpayComponent;
  /* DATA */
  myScriptElement: HTMLScriptElement;
  orderedQuote: PreviewQuoteResponse;
  amountDetails: any[] = [];
  insurancePreview?: InsuranceOfferPreview;
  vehicleLogoSrc: string;
  tempBankCode: string;
  ibanBankLogoSrc: string;
  checkoutResponse: any;
  checkoutJsUrl: string;
  referenceId: string;
  clientQuoteId: string;
  endDate: string;
  returnState: CheckoutReturnState = {} as CheckoutReturnState;
  dataPayment: dataPayment = {} as dataPayment;
  previewImage: string = "";
  countDown!: Subscription;
  iban = {
    list: [] as Iban[],
  };
  imageList: [];
  imgTitle: string;
  /* UI */
  accordionsState = {
    isDriversDisplayed: true,
    isImageUploadDisplayed: true,
    isPaymentDisplayed: true,
    isCardPaymentDisplayed: false,
    isSadadPaymentDisplayed: false,
  };

  /* UI */
  paymentLoader: boolean = false;
  lang;
  model: any;
  isCaptrueImage = false;
  isCameraNotAllawed: boolean = false;
  isCameraNotSupported: boolean = false;
  stream: MediaStream;
  stream2: MediaStream;
  togglePaymentMethod: boolean = false;
  paymentMethod: PaymentMethod = {
    isApplePayment: false,
    isCardPayment: false,
    isSadadPayment: false,
    isTabbyPayment: false,
  };
  isBankLogoLoading: boolean = false;
  isBankConnected: boolean = false;
  isIbanInputVisibale: boolean = false;
  isEnteredIbanValid = undefined;
  isVerifyIbanLoading: boolean = false;
  viewPaymentCard: boolean = false;
  hasScrolledBanner: boolean = false;
  isContinuationModalDisplayed = false;
  leanModalDisplayed = false;
  leanAccessFrom = new Date();
  leanAccessTo: string;
  public isCollapsed = true;
  isWebcamModalDisplayed = false;
  isImageCaptured = false;
  isShowCapturedImage = false;
  isSavingOrder = false;
  showErrorHiddenAlert = false;
  hiddedErrorAlert = true;
  isLoadingCardView = false;
  isCardPaymentFormVisible = true;
  isCardPaymentFormLoading = false;
  isLoadingPaymentCard: boolean;
  checkoutId: string;
  counter = 0;
  tick = 1000;
  isResndSMSVisible: boolean = false;
  isSMSLinkVisited: boolean = false;
  paymentResultPageUrl: string = environment.appUrl + AppRoutes.paymentStatus; //checkout/status;
  selectedPaymentBrand: string = "MADA"; // MADA is selected by default
  completeChekout: boolean = false;
  /* validation */
  /* Alert */
  isSaveQuoteError = false;
  isSaveQuoteSuccess = false;
  errorMessage: string;
  successMessage;
  isErrorAlertVisible = false;
  isSuccessAlertVisible;
  validationErrors: string[];
  uploadedImgValid: boolean = true;
  isLoadingSendImagesLink: boolean = false;
  isSendImageLinkseccessfuly: boolean = false;
  isSendImageLinkFailed: boolean = false;
  isImagesUploadedSuccessfully: boolean = false;
  hasCheckedImagePreview = false;
  /* file Attachment */

  uploader: FileUploader;
  supportedFile: boolean = true;
  // File Upload
  allowedFileType: string[] = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "application/pdf",
  ];
  vehicleImages = ["Front", "Back", "Right", "Left", "Chassis"];
  isFrontVehicleImageIsUploaded: boolean = false;
  isBackVehicleImageIsUploaded: boolean = false;
  isRightVehicleImageIsUploaded: boolean = false;
  isLeftVehicleImageIsUploaded: boolean = false;
  isChassisVehicleImageIsUploaded: boolean = false;
  state = {
    attachment: {
      tempAttachment: [] as FileHandle[],
    },
  };
  Coupon: ApplyCoupon[] = [
    { id: 1, name: "Wazen" },
    { id: 2, name: "STC" },
  ];
  //
  isImageLoading: boolean = false;
  isImagesUploadedCom: boolean = false;
  isImagesNotUploaded: boolean = false;
  isSendSMSLoading: boolean = false;
  //
  isUploadCompelte = false;
  isQuoteGGiCom = true;
  /* FORM */
  checkoutDetailsFormGroup: FormGroup;
  couponForm: FormGroup;
  now = new Date("2023-08-07T12:00:00Z");
  selectedIban: any = null;
  effDate: any;
  public timerInterval: any;
  isExpired: boolean;
  display: any;
  isVerifyAccountLoading: boolean;
  isResending: boolean;
  verificationCode: number;
  isSubmitting: boolean;
  isUploaded: boolean;
  /*Verification Account Alert */
  verifyAccountErrorMessage;
  verifyAccountSuccessMessage;
  isVerifyAccountErrorAlertVisible;
  isVerifyAccountSuccessAlertVisible;
  verifyAccountValidationErrors: string[];
  constructor(
    private appService: AppService,
    private quoteService: QuoteService,
    private orderReviewService: OrderReviewService,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private el: ElementRef,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    window.scroll({ top: 0 });
    this.getPreviewQuote();
    this.initForms();
    this.quoteProduct = this.orderedQuote.quoteInfo.products[0];
    //this.referenceId = this.orderedQuote.quoteInfo.requestReferenceId;
    this.appService.getAppLang().subscribe((appLang) => (this.lang = appLang));
    this.watchIbanInputCompletion();
    this.sortAmountDetails();
    this.ggiAttachmetCheck();
    this.initUploader();
    if (this.quoteProduct.productTypeId == 2) {
      this.checkingImagePreview(
        this.orderedQuote?.quoteInfo.requestReferenceId
      );
    }
  }
  ngAfterViewInit() {
    // get iban code
    // this.getIbanCode();
  }
  // open dialog lean
  connectToLean(info: any) {
    let divEl: HTMLScriptElement;
    let currentLocation = window.location.href;
    divEl = document.createElement("script");
    divEl.src = "https://cdn.leantech.me/sa/link/sdk/web/latest/Lean.min.js";
    document.body.appendChild(divEl);

    let newDate = AppUtils.subtractMonths(this.now, 6);
    divEl.onload = () => {
      Lean.connect({
        app_token: info.app_token,
        permissions: ["accounts"],
        customer_id: info.customer_id,
        sandbox: true,
        callback: this.myCallback,
        fail_redirect_url:
          "https://docs.leantech.me/v2.0-KSA/page/failed-connection",
        success_redirect_url: currentLocation, // "https://docs.leantech.me/v2.0-KSA/page/successful-connection",
        language: this.lang,
        access_from: newDate,
        access_to: AppUtils.addDays(this.now, 1),
        customization: {
          button_text_color: "#fff",
          theme_color: "#00a2ae",
        },
      });
    };
  }
  // get iban
  getIbanCode() {
    this.paymentLoader = true;
    let clientID: IbanRequest = {
      clientId: JSON.parse(
        localStorage.getItem(LocallyStoredItemsKeys.PreviewQuoteResponse)
      ).insuredInfo.clientId,
    };
    this.orderReviewService.getIban(clientID).subscribe(
      async (response) => {
        this.leanAccessTo = response.entityExpirationDate;
        if (response.isSuccess && response.iban != null) {
          this.isBankConnected = true;
          this.isIbanInputVisibale = true;
        }
        this.setUserIbans(response.iban);
      },
      (error) => {
        if (error.error.statusCode === 400) {
          this.leanAccessTo = error.error.entityExpirationDate;
        }
        this.paymentLoader = false;
      }
    );
  }

  // get details for lean
  getDetailsForLean() {
    this.paymentLoader = true;
    let clientID: IbanRequest = {
      clientId: JSON.parse(
        localStorage.getItem(LocallyStoredItemsKeys.PreviewQuoteResponse)
      ).insuredInfo.clientId,
    };
    this.orderReviewService.getDetailsForLean(clientID).subscribe(
      (res) => {
        this.paymentLoader = false;
        this.connectToLean(res);
      },
      (err) => {
        this.paymentLoader = false;
        if (err.status === 500) {
          let apiResponse: ApiResponse = {
            responseMessage:
              this.lang === "en"
                ? "sorry, some error occurred. Please try again later"
                : "عفوآ, حدث خطا اثناء تحميل الصورة الرجاء المحاولة لاحقآ",
            validationErrors: null,
          };
          this.displayErrorAlert("ErrorAlert", apiResponse);
        } else {
          this.displayErrorAlert("ErrorAlert", err.error.responseMessage);
        }
        this.isErrorAlertVisible = true;
        setTimeout(() => (this.isErrorAlertVisible = false), 10000);
      }
    );
  }
  myCallback(responseObject) {
    // insert actions you want to perform on SDK close
  }

  // split user iban string to list
  setUserIbans(ibans: string) {
    let ibanList: string[] = ibans.split(";");
    this.iban.list = [];
    ibanList.filter((item, i) => {
      let ibanStr: string[] = item.split("-");
      if (i === 0) this.checkoutForm.ibanCtrl.value = ibanStr[0];
      let iban: Iban = {
        id: i,
        code: ibanStr[0],
        name: item,
      };
      this.iban.list.push(iban);
    });
    // this.checkoutDetailsFormGroup.get("ibanCtrl").setValue(this.iban.list[0]);
    this.selectedIban = this.iban.list[0].code;
  }

  async setIbanBankLogo(iban: string) {
    // // Display loader
    this.isBankLogoLoading = true;
    // Get bank code
    let bankCode = iban[3] + iban[4];
    this.ibanBankLogoSrc =
      "assets/images/banks-logos-codes/" + bankCode + ".png";
    // // 1 sec of loading
    await AppUtils.delay(1000);
    // Conceal loader
    this.isBankLogoLoading = false;
  }
  /* -------------------------------------------------------------------------- */
  /*                               USER INTERFACE                               */
  /* -------------------------------------------------------------------------- */

  // get preview vehicle image if exist
  getPreviewVehicleImage() {
    this.orderReviewService
      .attachmentPreview(this.orderedQuote?.quoteInfo.requestReferenceId)
      .subscribe((res) => {});
  }
  // open Lean Modal
  openLeanModal(modal: any) {
    // If closed
    if (!this.paymentLoader) {
      if (!this.leanModalDisplayed) {
        // Open the modal
        this.modalService.open(modal, {
          size: "md",
          windowClass: "model",
          centered: true,
          beforeDismiss: () => {
            // Switch flag when dissmissed to uncheck the checkbox
            this.leanModalDisplayed = false;
            // Close the modal
            return true;
          },
        });
        // Set flag to check the checkbox
        this.leanModalDisplayed = true;
      }
    }
  }
  openContinuationModal(modal: any) {
    // If closed
    if (!this.isContinuationModalDisplayed) {
      // Open the modal
      this.modalService.open(modal, {
        size: "lg",
        windowClass: "model",
        centered: true,
        beforeDismiss: () => {
          // Switch flag when dissmissed to uncheck the checkbox
          this.isContinuationModalDisplayed = false;
          // Close the modal
          return true;
        },
      });
      // Set flag to check the checkbox
      this.isContinuationModalDisplayed = true;
    }
  }

  // Window scroll events
  @HostListener("window:scroll", ["$event"])
  onScroll(event) {
    if (window.pageYOffset > 100) this.hasScrolledBanner = true;
    else this.hasScrolledBanner = false;
  }

  /**
   * Initializes the IBAN form
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private initForms() {
    this.checkoutDetailsFormGroup = this.formBuilder.group(
      {
        ibanCtrl: [
          "SA",
          Validators.compose([Validators.required, IbanValidator]),
        ],
        emailCtrl: [
          this.orderedQuote?.insuredInfo.email,
          Validators.compose([
            Validators.required,
            Validators.pattern(/\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/),
          ]),
        ],
        phoneNumberCtrl: [
          this.orderedQuote?.insuredInfo.phoneNumber,
          Validators.compose([Validators.required, Validators.minLength(9)]),
        ],
      },
      { validator: checkPhoneNumberValidation }
    );
    this.couponForm = this.formBuilder.group({
      CouponType: [null, Validators.required],
      Coupon: [null, Validators.required],
    });
  }
  private setSelectedPaymentQuoteId(): void {
    this.paymentResultPageUrl = AppRoutes.paymentStatus;
  }

  /* -------------------------------------------------------------------------- */

  /*                       Sort Amount Details in checkout                      */
  /* -------------------------------------------------------------------------- */
  sortAmountDetails() {
    let extraBenefits: Benefit[] = this.quoteProduct.benefits.filter(
      (benefit) => benefit.benefitTypeId == 1
    );
    let accidentsPremium: PremiumBreakDown =
      this.quoteProduct.deductibles[0].premiumBreakdowns.filter(
        (pre) => pre.breakdownTypeId == 4
      )[0];
    let valueAddedTax: PremiumBreakDown =
      this.quoteProduct.deductibles[0].premiumBreakdowns.filter(
        (pre) => pre.breakdownTypeId == 5
      )[0];
    let annualPremium: PremiumBreakDown =
      this.quoteProduct.deductibles[0].premiumBreakdowns.filter(
        (pre) => pre.breakdownTypeId == 6
      )[0];

    extraBenefits?.forEach((benefit) => {
      this.amountDetails?.push({
        name: benefit.name,
        nameAr: benefit.nameAr,
        price: benefit.benefitAmount,
      });
    });
    // تحميل إضافي بسبب الحوادث طوكيو مارين
    // if (accidentsPremium) this.amountDetails?.push({
    //    name: accidentsPremium.name,
    //    nameAr: accidentsPremium.nameAr,
    //    price: accidentsPremium.breakdownAmount
    // })
    if (annualPremium)
      this.amountDetails?.push({
        name: "Premium",
        nameAr: "القسط الأساسي",
        price: annualPremium.breakdownAmount,
        underLine: true,
        bold: true,
      });
    this.amountDetails?.push({
      name: "Total Premium",
      nameAr: "إجمالي المبلغ",
      price:
        this.quoteProduct.deductibles[0].totalAdditionalBenefits +
        annualPremium.breakdownAmount,
      bold: true,
    });
    this.amountDetails?.push({
      name: valueAddedTax.name + " (15%) ",
      nameAr: valueAddedTax.nameAr + " (15%)",
      price: valueAddedTax.breakdownAmount,
      underLine: true,
    });
    this.amountDetails?.push({
      name: "Total Price with added TAX",
      nameAr: "إجمالي المبلغ شامل الضريبة",
      price: this.quoteProduct.deductibles[0].policyPremium,
      bold: true,
    });
    this.dataPayment.totleAmount =
      this.quoteProduct.deductibles[0].policyPremium;
    this.dataPayment.clientId = this.orderedQuote.insuredInfo.clientId;
  }
  /**
   * Returns the IBAN form group controls
   *
   * @readonly
   * @type {*}
   * @memberof OrderReviewComponent
   */
  get checkoutForm(): any {
    return this.checkoutDetailsFormGroup.controls;
  }

  /**
   * Displays an alert with the retreived message(s) from an API response
   * @private
   * @param {('ErrorAlert' | 'SuccessAlert')} alertType Specifies the alert type of Success or Error
   * @param {string} message The message to be displayed on the alert
   * @memberof AuthenticationPageComponent
   */

  private displayErrorAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse: ApiResponse
  ): void {
    // Set error message
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.errorMessage = apiResponse.responseMessage;

        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];

          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );

          // Set the validation errors
          this.validationErrors = errorsArr;
        } else {
          this.validationErrors = null;
        }

        break;
      case "SuccessAlert":
        // Set the success message
        this.successMessage = apiResponse.responseMessage;
        break;
      default:
        break;
    }
  }
  iD: string;
  /* -------------------------------------------------------------------------- */
  /*                                  WATCHERS                                  */
  /* -------------------------------------------------------------------------- */
  /**
   * Gets the entered IBAN and displays the bank based on it
   * @param event The IBAN input field event
   * @memberof OrderReviewComponent
   */
  async setIbanBank(event) {
    // Apply IBAN mask (space every 4 characters)
    var target = event.target,
      position = target.selectionEnd,
      length = target.value.length;
    target.value = target.value
      .replace(/[^\dA-Z]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();
    target.selectionEnd = position +=
      target.value.charAt(position - 1) === " " &&
      target.value.charAt(length - 1) === " " &&
      length !== target.value.length
        ? 1
        : 0;
    // Get input value
    let input: string = event.target.value.replace(" ", "");

    // Get bank code
    let bankCode = input[4] + input[5];
    this.iD = bankCode;
    // Check if entered code is different from previous code
    if (
      input.length >= 6 &&
      this.checkoutForm.ibanCtrl.valid &&
      bankCode != this.tempBankCode
    ) {
      // Update the value of the temp/previous entered code and set it to current
      this.tempBankCode = bankCode;
      // Set the logo image src
      this.ibanBankLogoSrc =
        "assets/images/banks-logos-codes/" + bankCode + ".png";
      // Display loader
      this.isBankLogoLoading = true;
      // 1 sec of loading
      await AppUtils.delay(1000);
      // Conceal loader
      this.isBankLogoLoading = false;
    }
  }
  /**
   * Prevents the user from deleting the IBAN country code "SA"
   *
   * @param {*} event The IBAN input keydown event
   * @memberof OrderReviewComponent
   */
  onIbanKeydown(event): void {
    var key = event.keyCode || event.charCode;
    if ((key == 8 || key == 46) && event.target.value.length <= 2) {
      event.preventDefault();
    }
  }

  /* open Pay modal */
  openConfirmPaymentModal(content2: any) {
    if (
      !this.isImagesUploadedSuccessfully &&
      this.quoteProduct.productTypeId === 2
    ) {
      let message =
        this.lang === "en"
          ? "sorry, you should upload car Picture first"
          : "عفوآ ، يجب إرفاق صور السيارة اولاً";
      this.showNotVerifiedMessage(message);
      return;
    }
    if (this.checkoutDetailsFormGroup.invalid) {
      // || !this.isVerifyIbanLoading
      let message =
        this.lang === "en"
          ? "sorry, this action need verified iban"
          : "عفوآ ، هذا الإجراء يحتاج إلى التحقق من إيبان";
      // this.showNotVerifiedMessage(message);
      this.checkoutDetailsFormGroup.markAllAsTouched();
      return;
    }
    if (!this.isEnteredIbanValid) {
      let message =
        this.lang === "en"
          ? "sorry, this action need verified iban"
          : "عفوآ ، هذا الإجراء يحتاج إلى التحقق من إيبان";
      this.showNotVerifiedMessage(message);
      this.checkoutDetailsFormGroup.markAllAsTouched();
      return;
    }
    this.modalService.open(content2, {
      size: "md",
      centered: true,
    });
  }

  // Verify Iban
  verifyIban() {
    this.isVerifyIbanLoading = true;
    let ibanCtrl = this.checkoutDetailsFormGroup.get("ibanCtrl");
    let iban = this.checkoutForm.ibanCtrl.value.replace(/ /g, "");

    let verifyIbanRequest: IbanVerificationRequest = {
      type: "PERSONAL",
      identificationNumber:
        iban === "SA2810000011100000461309"
          ? 1106972886
          : JSON.parse(
              localStorage.getItem(LocallyStoredItemsKeys.PreviewQuoteResponse)
            ).insuredInfo.identityNumber,
      iban: iban,
    };
    this.orderReviewService.verifyIban(verifyIbanRequest).subscribe(
      (res) => {
        this.isVerifyIbanLoading = false;
        if (res.verifications?.iban_ownership_verified) {
          this.setIbanBankLogo(ibanCtrl.value);
          this.isEnteredIbanValid = true;
        } else {
          this.ibanBankLogoSrc = null;
          this.isEnteredIbanValid = false;
          let message =
            this.lang === "en"
              ? "Sorry, the owner of the iban has not been verified"
              : "عفوآ، لم يتم التحقق من مالك الإيبان";
          this.showNotVerifiedMessage(message);
        }
      },
      (err) => {
        this.isVerifyIbanLoading = false;
        let message =
          this.lang === "en"
            ? "Sorry, some errors occurred when trying to verify the iban"
            : "عفوآ، حدثت بعض الأخطاء أثناء محاولة التحقق من رقم الإيبان";
        this.showNotVerifiedMessage(message);
      }
    );
  }

  /**
   * Waits until user finishes typing the IBAN and then sends the checkoutID retrieval request
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private watchIbanInputCompletion(): void {
    let ibanCtrl = this.checkoutDetailsFormGroup.get("ibanCtrl");
    ibanCtrl.statusChanges.subscribe((status) => {
      if (status === "INVALID") {
        this.isEnteredIbanValid = undefined;
        this.ibanBankLogoSrc = null;
      }
    });
  }

  // display not verified iban message
  showNotVerifiedMessage(message: string) {
    let apiResponse: ApiResponse = {
      responseMessage: message,
      validationErrors: null,
    };
    this.displayErrorAlert("ErrorAlert", apiResponse);

    this.isErrorAlertVisible = true;
    setTimeout(() => (this.isErrorAlertVisible = false), 10000);
  }

  /**
   * Retrieves the preview quote / checkout data
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private getPreviewQuote(): void {
    this.orderedQuote = this.quoteService.getPreviewQuote();
    this.orderedQuote.quoteInfo = this.orderedQuote.quoteInfo;
    // set effictive date
    // this.effDate = this.getDate(
    //   this.orderedQuote.quoteInfo.products[0].policyEffectiveDate
    // );
    this.effDate = this.orderedQuote.quoteInfo.products[0].policyEffectiveDate;

    // this.orderedQuote.quoteInfo.products[0].policyEffectiveDate =
    //   localStorage.getItem("policyEffectiveDate");

    // Sort driver array to start with main driver
    this.orderedQuote.vehicleDrivers.sort((a, b) =>
      a.typeId > b.typeId ? 1 : -1
    );
    // this.orderedQuote.quoteInfo.isSelectedForOrdering = true;
    // Set the user's vehicle model logo source
    if (this.orderedQuote.vehicleInfo.makerLogo) {
      this.vehicleLogoSrc =
        environment.apiAssetsUrl + this.orderedQuote.vehicleInfo.makerLogo;
    }
  }

  // format effictive date
  getDate(date: string) {
    const d = new Date(date);
    const day = this.padZero(d.getUTCDate());
    const month = this.padZero(d.getUTCMonth() + 1); // months are zero-based
    const year = d.getUTCFullYear();

    return `${day}-${month}-${year}`;
  }

  private padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }
  getCompanyLogo(): string {
    return (
      environment.apiAssetsUrl +
      "IC/" +
      this.orderedQuote.quoteInfo.insuranceCompany.logo
    );
  }

  // start timer counter
  countDwon() {
    this.countDown = timer(0, this.tick)
      .pipe(take(this.counter))
      .subscribe(() => {
        --this.counter;
        if (this.counter == 0) {
          this.countDown.unsubscribe();
          this.isResndSMSVisible = false;
        }
      });
  }

  // display timer UI
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ("00" + minutes).slice(-2) +
      ":" +
      ("00" + Math.floor(value - minutes * 60)).slice(-2)
    );
  }

  /* -------------------------------------------------------------------------- */
  /*                              API / SUBMIT                                  */
  /* -------------------------------------------------------------------------- */
  /* Timer for 3 mins count down before OTP expiration */

  /*                             OTP                                  */
  /* -------------------------------------------------------------------------- */

  initTimer(minute: number) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    const prefix = minute < 10 ? "0" : "";
    this.timerInterval = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;
      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;
      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;
      if (seconds == 0) {
        this.isExpired = true;
      }
    }, 1000);
  }

  sendVerificationCode(): void {
    // Get phone number and user ID from local storage
    // Construct resend request data
    let userVerficationData: RegistrationResponse = {
      userId: localStorage.getItem(LocallyStoredItemsKeys.userId),
      phoneNumber: localStorage.getItem(LocallyStoredItemsKeys.phoneNumber),
      code: "1",
    };
    // Resend the request
    this.authService.GetPaymentCode(userVerficationData).subscribe(
      (res: any) => {
        if (res.isSuccess) {
          this.displayVerificationAlert("SuccessAlert", res);
          // this.initTimer(1);
          this.counter = 60;
          this.countDwon();
        }
      },
      (err) => {
        this.modalService.dismissAll();
        this.isVerifyAccountLoading = false;
        this.isResending = false;
        // Display alert
        this.displayVerificationAlert("ErrorAlert", err.error);
      }
    );
  }

  validateVerificationCode(): void {
    // Set submission state to true (used for form validation)
    this.isSubmitting = true;
    const verificationReq: UserVerificationOTP = {
      userId: localStorage.getItem(LocallyStoredItemsKeys.userId),
      phoneNumber: localStorage.getItem(LocallyStoredItemsKeys.phoneNumber),
      code: this.verificationCode,
    };
    if (this.counter === 0) {
      // this.isExpired = true;
      this.displayVerificationAlert("ErrorAlert", {
        isSuccess: false,
        responseMessage:
          this.lang === "ar"
            ? "لقد انتهت صلاحية كود التحقق"
            : "Your OTP has expired.",
        statusCode: 603,
      });
      this.isSubmitting = false;
      return;
    } else {
      if (this.verificationCode) {
        // Show the loader on button
        this.isVerifyAccountLoading = true;
        // Send the request
        this.authService.sendVerificationCode(verificationReq).subscribe(
          (res: any) => {
            // Stop the loader
            this.isVerifyAccountLoading = false;
            if (res.isSuccess) {
              this.getCheckout();
              this.modalService.dismissAll();
            } else {
              this.displayVerificationAlert("SuccessAlert", res);
            }
          },
          (err) => {
            // Close modal on server error

            // Stop the loader
            this.isVerifyAccountLoading = false;
            this.displayVerificationAlert("ErrorAlert", err.error);
          }
        );
      }
    }
  }

  resendVerficationCode(): void {
    // Display loader
    this.isResending = true;
    this.isVerifyAccountLoading = true;

    // Get phone number and user ID from local storage
    // Construct resend request data
    let userVerficationData: RegistrationResponse = {
      userId: localStorage.getItem(LocallyStoredItemsKeys.userId),
      phoneNumber: localStorage.getItem(LocallyStoredItemsKeys.phoneNumber),
      code: "1",
    };
    // Resend the request
    this.authService.resendVerficationCode(userVerficationData).subscribe(
      (res: any) => {
        // Stop the loader
        this.isVerifyAccountLoading = false;
        this.isResending = false;
        // Display alert
        if (res.isSuccess) {
          clearInterval(this.counter);
          // this.isExpired = false;
          // this.initTimer(2);
          this.counter = 60;
          this.countDwon();
          this.displayAlert("SuccessAlert", res);
        } else this.displayVerificationAlert("ErrorAlert", res);
      },
      (err) => {
        // Close modal on server error
        this.modalService.dismissAll();
        // Stop the loader
        this.isVerifyAccountLoading = false;
        this.isResending = false;
        // Display alert
        this.displayVerificationAlert("ErrorAlert", err.error);
      }
    );
  }

  onOtpChange(e: any) {
    this.verificationCode = e;
  }

  closeActiveModal() {
    clearInterval(this.timerInterval);
    this.modalService.dismissAll();
  }

  get isRequested(): boolean {
    return this.verificationCode?.toString().length !== 6;
  }
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */

  applePayment() {
    this.paymentMethod.isApplePayment = true;
    this.paymentMethod.isCardPayment = false;
    this.paymentMethod.isSadadPayment = false;
    this.paymentMethod.isTabbyPayment = false;
    this.viewPaymentCard = false;
    this.changePaymentMethod(5, true);
  }

  sadadPayment() {
    // this.paymentMethod.isSadadPayment = true;
    // this.viewPaymentCard = false;
    // this.paymentMethod.isApplePayment = false;
    // this.paymentMethod.isCardPayment = false;
    // this.paymentMethod.isTabbyPayment = false;
    // this.changePaymentMethod(4, true);
  }

  tabbyPayment() {
    this.paymentMethod.isTabbyPayment = true;
    this.paymentMethod.isApplePayment = false;
    this.paymentMethod.isSadadPayment = false;
    this.paymentMethod.isCardPayment = false;
    this.viewPaymentCard = false;
    this.changePaymentMethod(6, true);
  }

  cardPayment() {
    this.paymentMethod.isCardPayment = true;
    this.paymentMethod.isApplePayment = false;
    this.paymentMethod.isSadadPayment = false;
    this.paymentMethod.isTabbyPayment = false;
    this.changePaymentMethod(1, true);
  }

  orderPayment() {
    this.sendVerificationCode();
    this.modalService.open(this.verify, {
      size: "md",
      centered: true,
      backdrop: "static",
      keyboard: false,
    });
    clearInterval(this.timerInterval);
    for (const key of Object.keys(this.checkoutDetailsFormGroup.controls)) {
      if (this.checkoutDetailsFormGroup.controls[key].invalid) {
        const invalidControl = this.el.nativeElement.querySelector(
          '[formcontrolname="' + key + '"]'
        );
        invalidControl.focus();
        this.checkoutDetailsFormGroup.markAllAsTouched();
        return;
      }
    }
  }

  getCheckout() {
    this.isLoadingPaymentCard = true;
    this.completeChekout = true;
    setTimeout(() => {
      this.isLoadingPaymentCard = false;
    }, 3000);
    let enteredIban = this.checkoutForm.ibanCtrl.value.replace(" ", "");
    let bankCode = parseInt(enteredIban[4] + enteredIban[5]);
    let bankId = BanksIds.banks.filter((bank) => bank.code == bankCode)[0].id;
    let checkoutRequest = CheckoutUtils.constructCheckoutRequest(
      this.orderedQuote
    );
    checkoutRequest = Object.assign(checkoutRequest, {
      paymentMethodId: 1,
      clientId: this.orderedQuote.insuredInfo.clientId,
      iban: enteredIban.replace(/ /g, ""),
      bankId: bankId,
      bankCode: bankCode,
      email: this.checkoutForm.emailCtrl.value,
      phoneNumber: this.checkoutForm.phoneNumberCtrl.value,
    });

    let previousContactDetails = {
      email: this.orderedQuote.insuredInfo.email,
      phoneNumber: this.orderedQuote.insuredInfo.phoneNumber,
    };
    let currentContactDetails = {
      email: this.checkoutForm.emailCtrl.value,
      phoneNumber: this.checkoutForm.phoneNumberCtrl.value,
    };

    JSON.stringify(previousContactDetails) ===
    JSON.stringify(currentContactDetails)
      ? (checkoutRequest.isContactUpdated = false)
      : (checkoutRequest.isContactUpdated = true);

    this.orderReviewService.getPaymentCard(checkoutRequest).subscribe(
      (res: any) => {
        if (res.isSuccess) {
          this.checkoutId = res.data.checkoutJsUrl;
          this.dataPayment.clientQuoteId = res.clientQuoteId;
          this.checkoutResponse = res;
          this.directPay.getPaymentValue();
          this.completeChekout = false;
          this.renderCardPaymentform();
        }
      },
      (err) => {
        this.isLoadingPaymentCard = true;
        setTimeout(() => {
          this.isLoadingPaymentCard = false;
        }, 3000);
        this.viewPaymentCard = false;
        this.isErrorAlertVisible = true;
        setTimeout(() => (this.isErrorAlertVisible = false), 6000);
        this.displayAlert("ErrorAlert", err.error);
        this.togglePaymentMethod = false;
      }
    );
  }

  changePaymentMethod(paymentMethodId: number, isCreditCard: boolean) {
    if (!this.isEnteredIbanValid) {
      let message =
        this.lang === "en"
          ? "sorry, this action need verified iban"
          : "عفوآ ، هذا الإجراء يحتاج إلى التحقق من إيبان";
      this.showNotVerifiedMessage(message);
      this.checkoutDetailsFormGroup.markAllAsTouched();
      return;
    }
    if (
      this.quoteProduct.productTypeId === 1 ||
      this.quoteProduct.productTypeId === 2
    ) {
      this.isUploadCompelte = true;
      this.uploadedImgValid = true;
    }
    // if (!this.isUploadCompelte || !this.uploadedImgValid) {
    //   this.isImagesNotUploaded = !this.isImagesNotUploaded;
    //   setTimeout(() => (this.isImagesNotUploaded = false), 10000);
    //   window.scrollTo({ top: 300, behavior: "smooth" });
    //   return;
    // }

    for (const key of Object.keys(this.checkoutDetailsFormGroup.controls)) {
      if (this.checkoutDetailsFormGroup.controls[key].invalid) {
        // const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
        // invalidControl.focus();
        this.checkoutDetailsFormGroup.markAllAsTouched();
        this.paymentMethod.isCardPayment = false;
        return;
      }
    }

    this.togglePaymentMethod = isCreditCard;
    if (this.togglePaymentMethod) {
      this.isLoadingPaymentCard = true;
      // setTimeout(() => { this.isLoadingPaymentCard = false; }, 3000);
      let enteredIban = this.checkoutForm.ibanCtrl.value.replace(/ /g, "");
      let bankCode =
        parseInt(enteredIban[4] + enteredIban[5]) === 0
          ? 100
          : parseInt(enteredIban[4] + enteredIban[5]);
      let bankId = BanksIds.banks.filter((bank) => bank.code == bankCode)[0]
        ?.id;

      let checkoutRequest = CheckoutUtils.constructCheckoutRequest(
        this.orderedQuote
      );
      checkoutRequest.productTypeId =
        this.orderedQuote.quoteInfo.products[0].productTypeId;
      checkoutRequest = Object.assign(checkoutRequest, {
        paymentMethodId: paymentMethodId,
        clientId: this.orderedQuote.insuredInfo.clientId,
        clientIdentifierNumber: JSON.parse(
          localStorage.getItem(LocallyStoredItemsKeys.PreviewQuoteResponse)
        ).insuredInfo.identityNumber,
        iban: enteredIban.replace(/ /g, ""),
        bankId: bankId,
        bankCode: bankCode,
        email: this.checkoutForm.emailCtrl.value,
        phoneNumber: this.checkoutForm.phoneNumberCtrl.value,
      });
      checkoutRequest.quote.product.productTypeId =
        this.orderedQuote.quoteInfo.products[0].productTypeId;

      let previousContactDetails = {
        email: this.orderedQuote.insuredInfo.clientId,
        phoneNumber: this.orderedQuote.insuredInfo.phoneNumber,
      };
      let currentContactDetails = {
        email: this.checkoutForm.emailCtrl.value,
        phoneNumber: this.checkoutForm.phoneNumberCtrl.value,
      };

      JSON.stringify(previousContactDetails) ===
      JSON.stringify(currentContactDetails)
        ? (checkoutRequest.isContactUpdated = true)
        : (checkoutRequest.isContactUpdated = true);
      this.orderReviewService.getPaymentCard(checkoutRequest).subscribe(
        (res: any) => {
          this.checkoutId = res.data.checkoutJsUrl;
          this.checkoutResponse = res;
          if (res) {
            if (this.paymentMethod.isCardPayment) {
              this.renderCardPaymentform();
            } else if (this.paymentMethod.isSadadPayment) {
              this.renderSadadPaymentform();
            } else if (this.paymentMethod.isTabbyPayment) {
              this.renderTabbyPaymentform();
            } else if (this.paymentMethod.isApplePayment) {
              this.renderApplePaymentform();
            }
          }
        },
        (err) => {
          this.isLoadingPaymentCard = true;
          setTimeout(() => {
            this.isLoadingPaymentCard = false;
          }, 3000);
          this.viewPaymentCard = false;
          this.isErrorAlertVisible = true;
          setTimeout(() => (this.isErrorAlertVisible = false), 8000);
          this.displayWarning(err.error);
        }
      );
    }
  }

  /**
   * Displays an alert with the retreived message(s) from an API response
   *
   * @private
   * @param {('ErrorAlert' | 'SuccessAlert')} alertType Specifies the alert type of Success or Error
   * @param {string} message The message to be displayed on the alert
   * @memberof AuthenticationPageComponent
   */
  private displayVerificationAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse: ApiResponse
  ): void {
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.errorMessage = apiResponse.responseMessage;

        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];

          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );

          // Set the validation errors
          this.validationErrors = errorsArr;
        } else {
          this.validationErrors = null;
        }

        // Display alert
        this.isVerifyAccountErrorAlertVisible = true;

        // Hide after timeout
        setTimeout(
          () => (this.isVerifyAccountErrorAlertVisible = false),
          10000
        );

        break;
      case "SuccessAlert":
        // Set the success message
        this.successMessage = apiResponse.responseMessage;

        // Display alert
        this.isVerifyAccountSuccessAlertVisible = true;

        // Hide after timeout
        setTimeout(
          () => (this.isVerifyAccountSuccessAlertVisible = false),
          10000
        );

        break;
      default:
        break;
    }
  }
  /* end Verification Account */

  /**
   * Displays an alert with the retreived message(s) from an API response
   * @private
   * @param {('ErrorAlert' | 'SuccessAlert')} alertType Specifies the alert type of Success or Error
   * @param {string} message The message to be displayed on the alert
   * @memberof AuthenticationPageComponent
   */

  private displayAlert(
    alertType: "ErrorAlert" | "SuccessAlert",
    apiResponse?: ApiResponse
  ): void {
    // Set error message
    switch (alertType) {
      case "ErrorAlert":
        // Set error message
        this.errorMessage = apiResponse.responseMessage;
        console.log(this.errorMessage);
        // Set validation errors
        if (
          apiResponse.validationErrors &&
          apiResponse.validationErrors.length > 0
        ) {
          // Init empty array
          let errorsArr: string[] = [];

          // Push the errors into the array
          apiResponse.validationErrors.forEach((err) =>
            errorsArr.push(err.description)
          );

          // Set the validation errors
          this.validationErrors = errorsArr;
        } else {
          this.validationErrors = null;
        }

        break;
      case "SuccessAlert":
        // Set the success message
        this.successMessage = apiResponse.responseMessage;

        // Display alert
        // this.isSuccessAlertVisible = true;

        // Hide after timeout
        // setTimeout(() => this.isSuccessAlertVisible = false, 4000);

        break;
      default:
        break;
    }
    // Display alert
    // this.isSuccessAlertVisible = true;
  }

  /**
   * Renders the  payment form by injecting their script and handles on load logic
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private async renderCardPaymentform() {
    this.viewPaymentCard = true;
    // On HyperPay form loaded
    hyperpayModule.paymentFormReady().then(async () => {
      // Watch for form submission to store data on return state
      this.watchPaymentSubmission();

      // Wait for 3 seconds to avoid the deformed rendering of the form when it is being injected into the HTML
      await AppUtils.delay(1000);

      // Conceal card form loader
      this.isCardPaymentFormLoading = false;

      // Add input data on the form if return state exists
      if (history.state.paymentErrorMsg || history.state.paymentErrorMsg === "")
        this.addPaymentReturnStateData();

      // Get selected brand changes
      let doc = document.getElementsByName("paymentBrand")[0];
      if (doc != null || doc != undefined) {
        doc.addEventListener("change", async (event: any) => {
          this.selectedPaymentBrand = event.target.value;
        });
      }
    });
    // loading dot .. style
    this.isLoadingPaymentCard = false;

    // On page language change
    this.appService?.getAppLang().subscribe(async (lang) => {
      let hyperpayOptsScriptId = "payment-form-options";
      let hyperpayScriptId = "hyperpay-script";
      this.checkoutId = this.checkoutResponse.data.checkoutJsUrl;

      localStorage.setItem(
        LocallyStoredItemsKeys.CheckoutReturnState,
        JSON.stringify(this.returnState)
      );

      /* ------------------------- Create HyperPay Scripts ------------------------ */

      // Create new HyperPay payment script
      const newHyperpayScript = document.createElement("script");
      newHyperpayScript.id = hyperpayScriptId;
      newHyperpayScript.src = this.checkoutResponse.data.checkoutJsUrl;
      newHyperpayScript.async = true;

      this.PaymentResponseCallBack(this.checkoutResponse);

      // Create new HyperPay options script with options
      const optionsScript = document.createElement("script");
      optionsScript.id = hyperpayOptsScriptId;
      optionsScript.type = "text/javascript";
      optionsScript.innerHTML = `
              var wpwlOptions = {
                 style: "card",
                 locale: "ar",
                 /*paymentTarget:"_top",*/
                 brandDetection: true,
                 brandDetectionType: "binlist",
                 brandDetectionPriority: ["MADA","VISA","MASTER"],
                 createRegistration: false,
                 onReady: () => hyperpayModule.paymentFormReady().resolve(),
              };
           `;

      this.togglePaymentMethod = false;

      /* ----------------- Replace old options script if it exists ---------------- */

      // Get old options script
      const oldOptsScript = document.getElementById(hyperpayOptsScriptId);

      // If it exists
      if (oldOptsScript) {
        // Display card form loader
        this.isCardPaymentFormLoading = true;

        // Remove the hyperpay payment form from DOM
        this.isCardPaymentFormVisible = false;

        await AppUtils.delay(100);

        // Display and add the hyperpay payment form to DOM again
        this.isCardPaymentFormVisible = true;

        // Replace options script with new language
        document.head.replaceChild(optionsScript, oldOptsScript);
      } else {
        // Append it
        document.head.appendChild(optionsScript);
      }

      /* ----------------- Reload old hyperpay script if it exists ---------------- */

      // Get the old hyperpay script
      const oldHyperpayScript = document.getElementById(hyperpayScriptId);

      // If it exists
      if (oldHyperpayScript) {
        // Reload it
        document.head.removeChild(oldHyperpayScript);
        document.head.appendChild(newHyperpayScript);
      } else {
        // Append it
        document.head.appendChild(newHyperpayScript);
      }

      // Wait for the card form to load
      while (
        !document.querySelector(".wpwl-form-card") ||
        window.getComputedStyle(
          window.document.querySelector(".wpwl-container-card")
        ).display === "none"
      ) {
        this.isCardPaymentFormLoading = true;
        await new Promise((r) => setTimeout(r, 500));
      }
      /*         this.PaymentResponseCallBack(this.checkoutResponse)
       */
      // Conceal the card form loader
      this.isCardPaymentFormLoading = false;
    });
  }

  private async renderSadadPaymentform() {
    this.viewPaymentCard = true;
    // On HyperPay form loaded
    hyperpayModule.paymentFormReady().then(async () => {
      // Watch for form submission to store data on return state
      this.watchPaymentSubmission();

      // Wait for 3 seconds to avoid the deformed rendering of the form when it is being injected into the HTML
      await AppUtils.delay(500);

      // Conceal card form loader
      this.isCardPaymentFormLoading = false;

      // Add input data on the form if return state exists
      if (history.state.paymentErrorMsg || history.state.paymentErrorMsg === "")
        this.addPaymentReturnStateData();

      // Get selected brand changes
      let doc = document.getElementsByName("paymentBrand")[0];
      if (doc != null || doc != undefined) {
        doc.addEventListener("change", async (event: any) => {
          this.selectedPaymentBrand = event.target.value;
        });
      }
    });
    // loading dot .. style
    this.isLoadingPaymentCard = false;

    // On page language change
    this.appService.getAppLang().subscribe(async (lang) => {
      let hyperpayOptsScriptId = "payment-form-options";
      let hyperpayScriptId = "hyperpay-script";
      this.checkoutId = this.checkoutResponse.data.checkoutJsUrl;

      localStorage.setItem(
        LocallyStoredItemsKeys.CheckoutReturnState,
        JSON.stringify(this.returnState)
      );

      /* ------------------------- Create HyperPay Scripts ------------------------ */

      // Create new HyperPay payment script
      const newHyperpayScript = document.createElement("script");
      newHyperpayScript.id = hyperpayScriptId;
      newHyperpayScript.src = this.checkoutResponse.data.checkoutJsUrl;
      newHyperpayScript.async = true;
      this.PaymentResponseCallBack(this.checkoutResponse);

      // Create new HyperPay options script with options
      const optionsScript = document.createElement("script");
      optionsScript.id = hyperpayOptsScriptId;
      optionsScript.type = "text/javascript";
      optionsScript.innerHTML = `
              var wpwlOptions = {
                 style: "card",
                 locale: "ar",
                 /*paymentTarget:"_top",*/
                 brandDetection: true,
                 brandDetectionType: "binlist",
                 brandDetectionPriority: ["SADAD_VA"],
                 createRegistration: false,
                 onReady: () => hyperpayModule.paymentFormReady().resolve(),
              };
           `;

      this.togglePaymentMethod = false;

      /* ----------------- Replace old options script if it exists ---------------- */

      // Get old options script
      const oldOptsScript = document.getElementById(hyperpayOptsScriptId);

      // If it exists
      if (oldOptsScript) {
        // Display card form loader
        this.isCardPaymentFormLoading = true;

        // Remove the hyperpay payment form from DOM
        this.isCardPaymentFormVisible = false;

        await AppUtils.delay(100);

        // Display and add the hyperpay payment form to DOM again
        this.isCardPaymentFormVisible = true;

        // Replace options script with new language
        document.head.replaceChild(optionsScript, oldOptsScript);
      } else {
        // Append it
        document.head.appendChild(optionsScript);
      }

      /* ----------------- Reload old hyperpay script if it exists ---------------- */

      // Get the old hyperpay script
      const oldHyperpayScript = document.getElementById(hyperpayScriptId);

      // If it exists
      if (oldHyperpayScript) {
        // Reload it
        document.head.removeChild(oldHyperpayScript);
        document.head.appendChild(newHyperpayScript);
      } else {
        // Append it
        document.head.appendChild(newHyperpayScript);
      }

      // Conceal the card form loader
      this.isCardPaymentFormLoading = false;
    });
  }

  private async renderTabbyPaymentform() {
    this.viewPaymentCard = true;
    // On HyperPay form loaded
    hyperpayModule.paymentFormReady().then(async () => {
      // Watch for form submission to store data on return state
      this.watchPaymentSubmission();

      // Wait for 3 seconds to avoid the deformed rendering of the form when it is being injected into the HTML
      await AppUtils.delay(500);

      // Conceal card form loader
      this.isCardPaymentFormLoading = false;

      // Add input data on the form if return state exists
      if (history.state.paymentErrorMsg || history.state.paymentErrorMsg === "")
        this.addPaymentReturnStateData();

      // Get selected brand changes
      let doc = document.getElementsByName("paymentBrand")[0];
      if (doc != null || doc != undefined) {
        doc.addEventListener("change", async (event: any) => {
          this.selectedPaymentBrand = event.target.value;
        });
      }
    });
    // loading dot .. style
    this.isLoadingPaymentCard = false;

    // On page language change
    this.appService.getAppLang().subscribe(async (lang) => {
      let hyperpayOptsScriptId = "payment-form-options";
      let hyperpayScriptId = "hyperpay-script";
      this.checkoutId = this.checkoutResponse.data.checkoutJsUrl;

      localStorage.setItem(
        LocallyStoredItemsKeys.CheckoutReturnState,
        JSON.stringify(this.returnState)
      );

      /* ------------------------- Create HyperPay Scripts ------------------------ */

      // Create new HyperPay payment script
      const newHyperpayScript = document.createElement("script");
      newHyperpayScript.id = hyperpayScriptId;
      newHyperpayScript.src = this.checkoutResponse.data.checkoutJsUrl;
      newHyperpayScript.async = true;

      this.PaymentResponseCallBack(this.checkoutResponse);

      // Create new HyperPay options script with options
      const optionsScript = document.createElement("script");
      optionsScript.id = hyperpayOptsScriptId;
      optionsScript.type = "text/javascript";
      optionsScript.innerHTML = `
              var wpwlOptions = {
                 style: "card",
                 locale: "ar",
                 /*paymentTarget:"_top",*/
                 brandDetection: true,
                 brandDetectionType: "binlist",
                 brandDetectionPriority: ["TABBY"],
                 createRegistration: false,
                 onReady: () => hyperpayModule.paymentFormReady().resolve(),
              };
           `;

      this.togglePaymentMethod = false;

      /* ----------------- Replace old options script if it exists ---------------- */

      // Get old options script
      const oldOptsScript = document.getElementById(hyperpayOptsScriptId);

      // If it exists
      if (oldOptsScript) {
        // Display card form loader
        this.isCardPaymentFormLoading = true;

        // Remove the hyperpay payment form from DOM
        this.isCardPaymentFormVisible = false;

        await AppUtils.delay(100);

        // Display and add the hyperpay payment form to DOM again
        this.isCardPaymentFormVisible = true;

        // Replace options script with new language
        document.head.replaceChild(optionsScript, oldOptsScript);
      } else {
        // Append it
        document.head.appendChild(optionsScript);
      }

      /* ----------------- Reload old hyperpay script if it exists ---------------- */

      // Get the old hyperpay script
      const oldHyperpayScript = document.getElementById(hyperpayScriptId);

      // If it exists
      if (oldHyperpayScript) {
        // Reload it
        document.head.removeChild(oldHyperpayScript);
        document.head.appendChild(newHyperpayScript);
      } else {
        // Append it
        document.head.appendChild(newHyperpayScript);
      }
      // Conceal the card form loader
      this.isCardPaymentFormLoading = false;
    });
  }

  private async renderApplePaymentform() {
    this.viewPaymentCard = true;
    // On HyperPay form loaded
    hyperpayModule.paymentFormReady().then(async () => {
      // Watch for form submission to store data on return state
      this.watchPaymentSubmission();

      // Wait for 3 seconds to avoid the deformed rendering of the form when it is being injected into the HTML
      await AppUtils.delay(500);

      // Conceal card form loader
      this.isCardPaymentFormLoading = false;

      // Add input data on the form if return state exists
      if (history.state.paymentErrorMsg || history.state.paymentErrorMsg === "")
        this.addPaymentReturnStateData();

      // Get selected brand changes
      let doc = document.getElementsByName("paymentBrand")[0];
      if (doc != null || doc != undefined) {
        doc.addEventListener("change", async (event: any) => {
          this.selectedPaymentBrand = event.target.value;
        });
      }
    });
    // loading dot .. style
    this.isLoadingPaymentCard = false;

    // On page language change
    this.appService.getAppLang().subscribe(async (lang) => {
      let hyperpayOptsScriptId = "payment-form-options";
      let hyperpayScriptId = "hyperpay-script";
      this.checkoutId = this.checkoutResponse.data.checkoutJsUrl;

      localStorage.setItem(
        LocallyStoredItemsKeys.CheckoutReturnState,
        JSON.stringify(this.returnState)
      );

      /* ------------------------- Create HyperPay Scripts ------------------------ */

      // Create new HyperPay payment script
      const newHyperpayScript = document.createElement("script");
      newHyperpayScript.id = hyperpayScriptId;
      newHyperpayScript.src = this.checkoutResponse.data.checkoutJsUrl;
      newHyperpayScript.async = true;
      this.PaymentResponseCallBack(this.checkoutResponse);

      // Create new HyperPay options script with options
      const optionsScript = document.createElement("script");
      optionsScript.id = hyperpayOptsScriptId;
      optionsScript.type = "text/javascript";
      optionsScript.innerHTML = `
              var wpwlOptions = {
                 style: "card",
                 locale: "ar",
                 /*paymentTarget:"_top",*/
                 brandDetection: true,
                 brandDetectionType: "binlist",
                 brandDetectionPriority: ["APPLEPAY"],
                 createRegistration: false,
                 onReady: () => hyperpayModule.paymentFormReady().resolve(),
              };
           `;

      this.togglePaymentMethod = false;

      /* ----------------- Replace old options script if it exists ---------------- */

      // Get old options script
      const oldOptsScript = document.getElementById(hyperpayOptsScriptId);

      // If it exists
      if (oldOptsScript) {
        // Display card form loader
        this.isCardPaymentFormLoading = true;

        // Remove the hyperpay payment form from DOM
        this.isCardPaymentFormVisible = false;

        await AppUtils.delay(100);

        // Display and add the hyperpay payment form to DOM again
        this.isCardPaymentFormVisible = true;

        // Replace options script with new language
        document.head.replaceChild(optionsScript, oldOptsScript);
      } else {
        // Append it
        document.head.appendChild(optionsScript);
      }

      /* ----------------- Reload old hyperpay script if it exists ---------------- */

      // Get the old hyperpay script
      const oldHyperpayScript = document.getElementById(hyperpayScriptId);

      // If it exists
      if (oldHyperpayScript) {
        // Reload it
        document.head.removeChild(oldHyperpayScript);
        document.head.appendChild(newHyperpayScript);
      } else {
        // Append it
        document.head.appendChild(newHyperpayScript);
      }
      // Conceal the card form loader
      this.isCardPaymentFormLoading = false;
    });
  }

  /* -------------------------------------------------------------------------- */
  /*                                RETURN STATE                                */
  /* -------------------------------------------------------------------------- */
  /**
   * Fills the checkout attachments and IBAN return state data
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private addReturnStateData(): void {
    // Get return state obj from local storage
    const returnStateObj: CheckoutReturnState = JSON.parse(
      localStorage.getItem(LocallyStoredItemsKeys.CheckoutReturnState)
    );
    this.returnState = returnStateObj;

    // Fill the IBAN and update the form
    this.checkoutDetailsFormGroup.get("ibanCtrl").setValue(returnStateObj.iban);
    this.checkoutDetailsFormGroup.updateValueAndValidity({
      onlySelf: false,
      emitEvent: true,
    });
  }

  /**
   * Stores the checkout state data to display it if user returns to the checkout page after payment failure.
   * Return State = The checkout data that would be stored
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private storeReturnState(): void {
    /*  this.PaymentResponseCallBack(this.checkoutResponse) */
    localStorage.setItem(
      LocallyStoredItemsKeys.CheckoutReturnState,
      JSON.stringify(this.returnState)
    );
  }

  private scrollToPayment(): void {
    const el = this.paymentBox.nativeElement;
    var bodyRect = document.body.getBoundingClientRect(),
      elemRect = el.getBoundingClientRect(),
      offset = elemRect.top - bodyRect.top - 550;
    window.scrollTo({ top: offset, behavior: "smooth" });
  }

  /**
   * Fills the payment form with return state data
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private addPaymentReturnStateData(): void {
    (<HTMLInputElement>(
      document.getElementsByClassName("wpwl-control-brand")[0]
    )).value = this.returnState.cardBrand;
    (<HTMLInputElement>(
      document.getElementsByClassName("wpwl-control-expiry")[0]
    )).value = this.returnState.cardExpiryDate;
    (<HTMLInputElement>(
      document.getElementsByClassName("wpwl-control-cardHolder")[0]
    )).value = this.returnState.cardHolder;
  }

  /**
   * Stores the payment data to return state object inorder to display it if user returns to the checkout page after payment failure
   *
   * @private
   * @memberof OrderReviewComponent
   */
  private watchPaymentSubmission(): void {
    let doc = document.getElementsByClassName("wpwl-button-pay")[0];
    if (doc) {
      doc.addEventListener("click", () => {
        this.returnState.cardBrand = (<HTMLInputElement>(
          document.getElementsByClassName("wpwl-control-brand")[0]
        )).value;
        this.returnState.cardExpiryDate = (<HTMLInputElement>(
          document.getElementsByClassName("wpwl-control-expiry")[0]
        )).value;
        this.returnState.cardHolder = (<HTMLInputElement>(
          document.getElementsByClassName("wpwl-control-cardHolder")[0]
        )).value;
        // this.PaymentResponseCallBack(this.checkoutResponse)
        // Store the return state data in case of payment error
        this.storeReturnState();
      });
    }
  }

  PaymentResponseCallBack(checkoutResponse) {
    console.log(checkoutResponse);
    let purchaseRequest: PolicyPurchase = {};
    purchaseRequest.clientPaymentId = checkoutResponse.clientPaymentId;
    purchaseRequest.clientQuoteId = checkoutResponse.clientQuoteId;
    purchaseRequest.pgReferenceId = checkoutResponse.data.pgReferenceId;
    localStorage.setItem(
      LocallyStoredItemsKeys.purchaseResponse,
      JSON.stringify(purchaseRequest)
    );
  }

  // Save Quotes
  saveQuote() {
    let savedQuoteRequest = CheckoutUtils.constructCheckoutRequest(
      this.orderedQuote
    );
    savedQuoteRequest = Object.assign(savedQuoteRequest, {
      clientId: this.orderedQuote.insuredInfo.clientId,
    });
    this.orderReviewService.saveQuote(savedQuoteRequest).subscribe(
      (res: any) => {
        this.checkoutResponse = res;
        this.modalService.dismissAll();
        this.isSuccessAlertVisible = true;
        setTimeout(() => (this.isSuccessAlertVisible = false), 4000);
        this.displayErrorAlert("SuccessAlert", res);
      },
      (error) => {
        this.modalService.dismissAll();
        this.displayErrorAlert("ErrorAlert", error.error);
        this.isSaveQuoteError = true;
        setTimeout(() => (this.isSaveQuoteError = false), 10000);
      }
    );
  }

  //  Display Warnings
  private displayWarning(apiResponse: ApiResponse): void {
    // Set error message
    this.errorMessage = apiResponse.responseMessage;

    // Set validation errors
    if (
      apiResponse.validationErrors &&
      apiResponse.validationErrors.length > 0
    ) {
      // Init empty array
      let errorsArr: string[] = [];
      // Push the errors into the array
      apiResponse.validationErrors.forEach((err) =>
        errorsArr.push(err.description)
      );
      // Set the validation errors
      this.validationErrors = errorsArr;
    } else {
      this.validationErrors = null;
    }
    //Display Warning
    this.isErrorAlertVisible = true;
    // setTimeout(() => this.isWarningVisible = false, 20000);
  }

  ggiAttachmetCheck() {
    let quoteInfo = this.orderedQuote?.quoteInfo;
    if (quoteInfo.products[0].productTypeId == 2) {
      this.isQuoteGGiCom = true;
    }
  }
  initUploader() {
    this.uploader = new FileUploader({ url: "", autoUpload: false });
  }

  sendUploadImageRequest() {
    this.isSendSMSLoading = true;
    this.isLoadingSendImagesLink = true;
    this.isSMSLinkVisited = true;
    if (this.counter != 0) {
      return;
    }
    let request: SendUploadImagesLinkRequest = {
      userId: this.orderedQuote?.insuredInfo.clientId,
      requestReferenceId: this.orderedQuote?.quoteInfo.requestReferenceId,
    };
    this.orderReviewService.sendUploadImageLink(request).subscribe((res) => {
      this.isSendSMSLoading = false;
      this.isLoadingSendImagesLink = false;
      this.isSendImageLinkseccessfuly = true;
      this.counter = 90;
      this.countDwon();
      this.isResndSMSVisible = true;
      setTimeout(() => (this.isSendImageLinkseccessfuly = false), 10000);
      res.responseMessage =
        "A message has been sent to your phone, visit the link in the content of the message to upload photos of the vehicle";
      this.displayErrorAlert("SuccessAlert", res);
      this.checkingImagePreview(request.requestReferenceId);
    }),
      (error) => {
        this.isSendSMSLoading = false;
        this.isLoadingSendImagesLink = false;
        this.isSendImageLinkFailed = true;
        setTimeout(() => (this.isSendImageLinkFailed = false), 10000);
        this.displayErrorAlert("ErrorAlert", error.error);
      };
  }

  checkingImagePreview(reqRefId: string) {
    if (this.hasCheckedImagePreview) {
      return; // Exit the function if it has already been called
    }
    this.hasCheckedImagePreview = true;
    // if (this.isImagesUploadedSuccessfully) {
    this.orderReviewService.checkingImagePreview(reqRefId).subscribe((res) => {
      if (res.length > 4) {
        this.imageList = res;
        this.isImagesUploadedSuccessfully = true;
        this.isSendImageLinkseccessfuly = false;
      }
    });
    // }
    return;
  }

  openVerticallyCentered(content) {
    this.modalService.open(content, { centered: true, size: "md" });
  }

  applyCoupon() {
    let applyCoupon: coupon = {
      couponType: this.couponForm.get("CouponType").value,
      couponCode: this.couponForm.get("Coupon").value,
    };
    if (this.couponForm.valid) {
      this.orderReviewService.applyCoupon(applyCoupon).subscribe((res) => {});
    }
    (error) => {
      this.displayErrorAlert("ErrorAlert", error.error);
    };
  }
}
